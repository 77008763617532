<template>
  <div :class="$style.root">
    <div class="mb-4">
      <h1 v-if="$route.meta.title" class="mb-0">
        {{$route.meta.title}} {{acceptance && `#${acceptance}`}}
      </h1>
    </div>
    <div class="mb-4 clearfix">
      <div v-if="status" class="float-right ml-2">
        <StatusHistory :value="status" />
      </div>
      <VBtn depressed @click="$router.push({ name: 'actsvr/AcceptanceListView' })">
        <VIcon>mdi-arrow-left</VIcon>
        Назад к списку
      </VBtn>
    </div>
    <div v-if="current" :class="$style.inner">
      <AcceptanceDetailForm v-if="isDraft || !acceptance" v-bind="{...current}" />
      <AcceptanceDetailReview v-else v-bind="{...current}" />
    </div>
  </div>
</template>

<script>
import { debounce, get } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { STATUSES } from '@/store/actsvr/enums';
import StatusHistory from '@/components/actsvr/StatusHistory/StatusHistory';
import AcceptanceDetailForm from '@/components/actsvr/AcceptanceDetailFrom/AcceptanceDetailFrom';
import AcceptanceDetailReview from '@/components/actsvr/AcceptanceDetailReview/AcceptanceDetailReview';

export default {
  name: 'AcceptanceDetailView',
  components: {
    StatusHistory,
    AcceptanceDetailForm,
    AcceptanceDetailReview,
  },
  props: {
    acceptance: { type: String }
  },
  computed: {
    ...mapGetters({
      pending: 'actsvr/pending',
      current: 'actsvr/acceptance',
    }),
    status() {
      return get(this.current, 'status');
    },
    isDraft() {
      return get(STATUSES, 'DRAFT.value') === this.status;
    },
  },
  methods: {
    ...mapActions({
      fetchAcceptanceDetail: 'actsvr/fetchAcceptanceDetail',
    }),
    onUpdateState: debounce(function() {
      const { acceptance } = this;
      this.fetchAcceptanceDetail({ id: acceptance });
    }, 500),
  },
  watch: {
    id: {
      immediate: true,
      handler: function() {
        this.onUpdateState();
      }
    },
  },
}
</script>

<style module lang="scss">
.root {
  $header-bg: #efefef;
  $border-color: #dee2e6;
  :global(.v-expansion-panel) {
    border: 1px solid $border-color;
    margin-bottom: -1px;
  }
  .inner {
    position: relative;
  }
}
</style>
